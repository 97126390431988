import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, Button, createTheme, CSSVariablesResolver, Image, MantineProvider, Text, Title} from "@mantine/core";
import {useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import cx from 'clsx';

import {globalContext} from "../App";
import logo from "../assets/images/logo_virtik_big.svg";
import maximize from "../assets/images/maximize.png"
import minimize from "../assets/images/minimize.png"
import Viewer360 from "../components/tour/Viewer360";
import CustomOverlay from "../components/tour/view/CustomOverlay";
import {IProject} from "../models/shared/IProject";
import {ScreenModeType, UiColorScheme} from "../models/shared/ITheme";
import sceneClasses from "../components/tour/view/Scenes.module.css";
import pageClasses from "./Page.module.css";
import {useFullscreenMode} from "../components/tour/hooks/useFullscreenMode";
import {useZoomControls} from "../components/tour/hooks/useZoomControls";
import {mainBlackBlueWhiteColor, mainBlackRedWhiteColor} from "../vars";
import {ViewMode} from "../models/shared/SceneType";

const StartScene = observer(({t, i18n}: any) => {
    const {link} = useParams();
    const [logoSrc, setLogo] = useState('');
    const [showStart, setStart] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [showViewer360, setViewer360] = useState(false);
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const api = global.api;
    const stateStore = global.stateStore
    const {toggleFullscreen} = useFullscreenMode()
    const [isError, setIsError] = useState<boolean>(false)
    const errorMessage: string =  t('Something went wrong. Tour is not loaded. Please check your internet connection and try again.')
    const {updateCameraAspect} = useZoomControls()
    const pageWrapperRef = useRef(null)
    const screenMode = useRef<ScreenModeType>(ScreenModeType.windowed);

    useEffect(() => {
        updateCameraAspect(pageWrapperRef);
    }, [stateStore.state.fullscreen]);

    useEffect(() => {
        api.getTourByReferenceCode(link).then((firebaseProject: IProject | null) => {
            if (!firebaseProject) {
                setIsError(true)
                setIsLoading(false)
                return;
            }

            tourStore.setLastVisitedTours(firebaseProject);

            if (firebaseProject?.logoPath && firebaseProject.logoPath !== "" && firebaseProject.logoPath !== "/") {
                setLogo(firebaseProject.logoPath);
            } else {
                setLogo(logo);
            }

            const checkData = stateStore.checkByKey('referenceCodeTour')

            if (!checkData.exist || link !== checkData.val) {
                stateStore.remove()
            } else {
                setStart(false);
            }

            setTourDataToStore(firebaseProject)
            setIsLoading(false)
            /* STOP REMOVING THIS USEFUL COMMENT */
            // startTour();
        }).catch((e) => {
            console.log(e);
            setIsError(true)
            setIsLoading(false)
        });
    }, []);

    function setTourDataToStore(project: IProject) {
        global.tourStore.setProjectData(project);
        global.tourStore.setProjectName(project.displayName);
        global.tourStore.setPlan(project.tour?.plan2d);
        global.tourStore.setTourData(project.tour)
        global.tourStore.setToursNodes(project.tour?.tourNodes.map(t => t.node))

        if (project.skin?.theme) {
            global.tourStore.setUiColorScheme(project.skin.theme.colorScheme.uiColorScheme)
            global.tourStore.setSettings(project.skin.theme)
            global.tourStore.setMenuType(project.skin.infoMenu?.type)
            global.tourStore.setIsOpenedMenu(project.skin.theme.tourDefaultSettings.general.showInfoMenu || false);
            screenMode.current = project.skin.theme.tourDefaultSettings?.general.screenMode;
            const checkData = stateStore.checkByKey('fullscreen')

            if (!checkData.exist) {
                stateStore.updateOne('fullscreen', screenMode.current === 'maximized', true)
            } else {
                stateStore.updateOne('fullscreen', checkData.val)
            }
        }
    }

    function startTour(reset: boolean = false) {
        loadStoreData(reset)
        setViewer360(true);
    }

    function loadStoreData(reset: boolean = false) {
        const mode = getMode(reset);
        stateStore.init({
            referenceCodeTour: global.tourStore.projectData?.referenceCode || '',
            mute: tourStore.generalSettings.isMute,
            fullscreen: screenMode.current === 'maximized',
            showSidebar: tourStore.generalSettings.showInfoMenu ?? true,
            isRightControlPanelExpanded: tourStore.generalSettings.isControlsCollapsed,
            showMinimap: tourStore.mode360TourSettings.show2dPlanMinimap,
            mode: mode
        }, reset)
    }

    function getMode(reset: boolean = false): ViewMode {
        const existData = stateStore.checkByKey('mode')

        if (existData.exist && !reset) {
            return existData.val
        }

        if (tourStore.generalSettings?.initialMode === undefined || ViewMode[tourStore.generalSettings.initialMode] === undefined) {
            return ViewMode.scene360
        }

        return ViewMode[tourStore.generalSettings.initialMode]
    }

    function toggleFullscreenBeforeLoad(force: boolean = false) {
        const checkData = stateStore.checkByKey('fullscreen')
        toggleFullscreen(!checkData.exist)
    }

    const themeOverride = createTheme({
        other: {
            secondaryUiColor: tourStore.uiColorScheme === UiColorScheme['Black&Red&White'] ? mainBlackRedWhiteColor : mainBlackBlueWhiteColor,
        }
    });

    const resolver: CSSVariablesResolver = (theme) => ({
        variables: {
            '--mantine-virtik-button-secondary-ui-color': theme.other.secondaryUiColor,
        },
        light: {},
        dark: {},
    });

    return (
        <MantineProvider theme={themeOverride} cssVariablesResolver={resolver}>
            <Box className={pageClasses.mainBlock}>
                <Box className={pageClasses.titleBlock}>
                    <Title size="h1">
                        {t('Virtual experience')}
                    </Title>
                </Box>

                <Box id="virtikPageContent"
                     ref={pageWrapperRef}
                     className={cx(
                         pageClasses.virtikContainer,
                         showViewer360 ? pageClasses.sceneContainer : pageClasses.startContainer,
                         stateStore.state.fullscreen ? sceneClasses.emulatedFullScreen : ''
                     )}>

                    <CustomOverlay visible={isLoading}></CustomOverlay>

                    {!isLoading &&
                        <>
                            {!isError &&
                                <>
                                    {showViewer360 &&
                                        <Viewer360 toggle={toggleFullscreen}/>
                                    }

                                    {!showViewer360 &&
                                        <Box className={pageClasses.previewContainer}>
                                            <Box className={pageClasses.logoBlock}>
                                                <Image src={logoSrc}/>
                                            </Box>

                                            <Box className={pageClasses.buttonBlock}>
                                                <Button onClick={() => {
                                                    startTour()
                                                }}>
                                                    {t(showStart ? 'Start tour' : 'Resume tour')}
                                                </Button>

                                                {!showStart &&
                                                    <Button onClick={() => {
                                                        startTour(true)
                                                    }}>
                                                        {t('Restart')}
                                                    </Button>
                                                }
                                            </Box>
                                        </Box>
                                    }
                                </>
                            }

                            {isError &&
                                <Box>
                                    <Box>
                                        <Title size="h3" c="white">
                                            {t('Unable to load the tour')}
                                        </Title>
                                    </Box>

                                    <Box className={pageClasses.errorBody}>
                                        <Text fz="lg" c="white">
                                            {errorMessage}
                                        </Text>
                                    </Box>

                                    <Box className={cx(pageClasses.buttonBlock, pageClasses.errorButton)}>
                                        <Button onClick={() => {
                                            window.location.reload()
                                        }}>
                                            {t('Try again')}
                                        </Button>

                                        <Button variant={"transparent"} onClick={() => {
                                            window.history.back()
                                        }}>
                                            {t('Cancel')}
                                        </Button>
                                    </Box>
                                </Box>
                            }

                            {!showViewer360 &&
                                <Box>
                                    <Button className={cx(sceneClasses.roundButton, pageClasses.fullscreenButton)}
                                            onClick={() => toggleFullscreenBeforeLoad(true)}>
                                        <Image src={stateStore.state.fullscreen ? minimize : maximize}/>
                                    </Button>
                                </Box>
                            }
                        </>
                    }
                </Box>
            </Box>
        </MantineProvider>
    );
})

export default withTranslation()(StartScene);
