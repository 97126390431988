import {I2DPlanScene} from "../../../models/shared/Plan2D";
import {HotspotBehaviourLocation} from "../../../models/shared/HotspotBehaviour";
import {ViewerContentType} from "../../../models/shared/SceneType";
import {IHotspot} from "../../../models/shared/IProjectBase";
import React, {ReactNode} from "react";
import {AddHotspot} from "../plan2d/AddHotspot";
import {HotspotVisualType} from "../../../models/shared/IHotspotVisual";
import {Box} from "@mantine/core";
import c from "../plan2d/Minimap2dPlan.module.css";
import {globalStore} from "../../../App";

/* Get the lowest order plan index from attached to current scene; otherwise get just the lowest order one*/
export function getSelectedPlanIndex(plan2DData: I2DPlanScene[], sceneStore): number {
    const plansAttachedToCurrentScene = plan2DData
        .filter((plan: I2DPlanScene) => plan?.linkedScenes &&
            Array.isArray(plan.linkedScenes) &&
            !!sceneStore.currentScene?.nodeName &&
            plan.linkedScenes.includes(sceneStore.currentScene!.nodeName)
        );
    switch (plansAttachedToCurrentScene.length) {
        case 0:
            return plan2DData.findIndex(p => p.order === Math.min(...plan2DData.map(plan => plan.order)));
        case 1:
            return plan2DData.findIndex(p => p.nodeName === plansAttachedToCurrentScene[0].nodeName);
        default:
            const lowestOrder = Math.min(...plansAttachedToCurrentScene.map(plan => plan.order));
            const selectedPlan = plansAttachedToCurrentScene.find(plan => plan.order === lowestOrder);
            return plan2DData.findIndex(p => p.nodeName === selectedPlan!.nodeName);
    }
}

export function loadScene(clickedHotspot, loadContent: CallableFunction) {
    const targetSceneName: string = (clickedHotspot.hotspotData.behaviourData as HotspotBehaviourLocation)?.transitionToScene;

    if (!targetSceneName) {
        return console.error('Cannot find scene to transfer, given name:', targetSceneName);
    }

    loadContent(ViewerContentType.scene360, targetSceneName)
}

function moveToTargetHotspot(hotspots: IHotspot[] = [], currenSceneName: string, container: HTMLDivElement, child: HTMLDivElement, planImage: HTMLImageElement) {
    const targetHotspotPos = hotspots
        .filter(h => currenSceneName === (h.hotspotData.behaviourData as HotspotBehaviourLocation).transitionToScene)
        .map(h => h.hotspotData.position)
        .slice(0, 1).shift();

    if (!targetHotspotPos) {
        return;
    }

    // wait before centering minimap after switching from maximap
    // use setTimeout for 2D plan mode as well for unification's sake
    setTimeout(() => {
        if (!planImage || !container) return

        const proportionImageY = planImage.naturalHeight / child?.clientHeight;
        const positionY = targetHotspotPos.y / proportionImageY;
        const positionYTemp = container.clientHeight / 2;
        container.scrollTop = positionY - positionYTemp;

        const proportionImageX = planImage.naturalWidth / child.clientWidth;
        const positionX = targetHotspotPos.x / proportionImageX
        const positionXTemp = container.clientWidth / 2;
        container.scrollLeft = positionX - positionXTemp;
    }, 100);
}

function getPlanHotspots(plan, planImage: HTMLImageElement, nodeName: string, loadContent: Function, isMinimap: boolean = false, sizeMinimap: string = '') {
    const nodes: ReactNode[] = [];
    const childNodePolygons: ReactNode[] = [];

    plan.forEach((elem, index) => {
        const renderedNodeHotspot = <AddHotspot hsd={elem.hotspotData}
                                                index={index}
                                                key={elem.hotspotData.name + index}
                                                planImage={planImage}
                                                isMinimap={isMinimap}
                                                currentSceneName={nodeName}
                                                loadContent={loadContent}
                                                sizeMinimap={sizeMinimap}/>;

        if (elem.hotspotData.visualType === HotspotVisualType.polygon) {
            childNodePolygons.push(renderedNodeHotspot);
        } else {
            nodes.push(renderedNodeHotspot)
        }
    });

    if (childNodePolygons.length > 0) {
        const parentNodePolygons =
            <Box style={{width: '100%', height: '100%'}}
                 className={c.polygonNew}
                 key={Math.floor(Date.now() / 1000)}
                 top={0}
                 left={0}>
                <svg height={'100%'} width={'100%'} style={{top: 0, left: 0}} className={c.polygonNew}>
                    {childNodePolygons}
                </svg>
            </Box>
        ;

        nodes.push(parentNodePolygons);
    }

    return <>{nodes}</>
}

export function actionMoveToCurrentHotspot(nodeName: string = '', container: string, child: string, image: string) {
    const containerItem = document.getElementById(container) as HTMLDivElement;
    const childItem = document.getElementById(child) as HTMLDivElement;
    const planImage = document.getElementById(image) as HTMLImageElement;
    const hotspots = globalStore.plan2DStore.getCurrentHotspots();
    moveToTargetHotspot(hotspots, nodeName, containerItem, childItem, planImage);
}

export function getRenderedHotspots(planImageId: string = '', loadContent, isMinimap: boolean = false, sizeMinimap: string = '') {
    const planImage = document.getElementById(planImageId) as HTMLImageElement;

    if (planImage) {
        return getPlanHotspots(
            globalStore.tourStore.plan[globalStore.plan2DStore.current2DPlanElement ?? 0]?.hotspots ?? [],
            planImage,
            globalStore.sceneStore.currentScene!?.nodeName ?? '',
            loadContent,
            isMinimap,
            sizeMinimap);
    }

    return null
}
