import React, {useContext, useEffect, useRef, useState} from 'react';
import ImageScene from "./ImageScene";
import VideoScene from "./VideoScene";
import MapScene from "./MapScene";
import MeasurementScene from "./MeasurementScene";
import {globalContext} from "../../App";
import classes from "./Viewer360.module.css";
import BottomSceneSwitcher from "./view/BottomSceneSwitcher";
import SidebarMenu from "./SidebarMenu";
import {Scene360Type, ViewerContentType, ViewMode} from "../../models/shared/SceneType";
import {IMenuSettings} from "../../models/shared/IMenuSettings";
import {INodeData} from "../../models/shared/IProjectBase";
import {HotspotBehaviourData, HotspotBehaviourLink, HotspotBehaviourType} from "../../models/shared/HotspotBehaviour";
import {observer} from "mobx-react-lite";
import {modals} from "@mantine/modals";
import ModalHotspots from "./modals/ModalHotspots"
import {defaultCameraRotation, mobileDeviceMaxWidth} from "../../vars";
import {Box, Button, Image, Text} from "@mantine/core";
import maximize from "../../assets/images/maximize.png";
import {withTranslation} from "react-i18next";
import {useWindowDimensions} from "./hooks/useWindowDimensions";
import {toJS} from "mobx";
import Minimap2dPlan from "./plan2d/Minimap2dPlan";
import {I2DPlanScene} from "../../models/shared/Plan2D";
import CustomOverlay from "./view/CustomOverlay";
import {pauseSceneSound, playMediaSafely, playSceneSound} from "./utils/AudioVideoUtils";
import {getSelectedPlanIndex} from "./utils/Plan2D";
import {useDidUpdate} from "@mantine/hooks";
import PlanMode from "./PlanMode";

const Viewer360 = observer(({t, toggle}: { t: any; toggle: Function }) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const stateStore = global.stateStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const [scene, setScene] = useState<any>();
    const [show2dPlan, setShow2dPlan] = useState<boolean>(false);
    const [minimap, setMinimap] = useState<any>();
    const sidebarData = useRef<IMenuSettings | null>(tourStore.projectData?.skin?.infoMenu ?? null);
    const sceneWrapperRef = useRef(null);
    const modalOptions = {
        closeOnClickOutside: false,
        size: 'auto',
        radius: 0,
        centered: true,
        withCloseButton: false,
        padding: 0,
        overlayProps: {
            opacity: 0,
        },
        zIndex: 9998,
    };
    const {orientation, width, height, isMobile} = useWindowDimensions()
    const showLoadingFor360Scene = tourStore.showLoadingFor360Scene;
    const plan2d: I2DPlanScene[] = tourStore.plan;

    useEffect(() => {
        init();
    }, [])

    function init(selectMode?: ViewMode) {
        const initMode = selectMode || stateStore.state.mode;

        switch (initMode) {
            case ViewMode.map:
                loadViewerContent(ViewerContentType.map)
                break;
            case ViewMode.measurement:
                loadViewerContent(ViewerContentType.measurement)
                break;
            case ViewMode.scene360:
                loadViewerContent(ViewerContentType.scene360, '', true)
                break;
            case ViewMode.plan:
                loadViewerContent(ViewerContentType.scene360, '', true, true)
                break;
            default:
                console.log('Unknown view mode')
                break
        }
    }

    function getNodeData(nodeName = ''): INodeData {
        if (nodeName !== '') {
            const nodeData = getTourByNodeName(nodeName)

            if (nodeData && Object.keys(nodeData).length > 0) {
                return nodeData;
            }
        }

        if ((stateStore.state?.sceneNodeName ?? '') !== '') {
            const nodeData = getTourByNodeName(stateStore.state?.sceneNodeName)

            if (nodeData && Object.keys(nodeData).length > 0) {
                return nodeData;
            }
        }

        if (tourStore.tourData?.entryNodeName) {
            const nodeData = getTourByNodeName(tourStore.tourData?.entryNodeName)

            if (nodeData && Object.keys(nodeData).length > 0) {
                return nodeData;
            }
        }

        return Object.values(toJS(tourStore.tourNodes))[0]
    }

    function getTourByNodeName(nodeName: string = ''): INodeData | null {
        if (nodeName === '') return null;

        return tourStore.tourNodes.find(item => item.nodeName === nodeName) ?? null
    }

    /** @param type standardized behaviour type
     * @param data standardized behaviour data
     * @param extra use for custom data not included into interfaces */
    function hotspotBehaviour(type: HotspotBehaviourType, data: HotspotBehaviourData = {}, extra: {[k: string]: any} = {}) {
        sceneStore.setHotspotVolumeOn(stateStore.state.mute)

        const parent: HTMLDivElement = sceneWrapperRef.current!;
        const viewportOffset = parent.getBoundingClientRect()
        const hotspotModalOptions = {
            ...modalOptions,
            withinPortal: false,
            xOffset: viewportOffset.left,
            yOffset: viewportOffset.top,
            overlayProps: {mx: viewportOffset.left, my: viewportOffset.top, backgroundOpacity: 0}
        }

        switch (type) {
            case HotspotBehaviourType.location:
                if (!extra?.sceneNodeName) {
                    return console.error('Missing params for scene transition! Given data:', data);
                }

                loadViewerContent(ViewerContentType.scene360, extra.sceneNodeName)
                break;
            case HotspotBehaviourType.audio:
                const assetsBody = document.getElementById('sceneAssets') as HTMLElement;
                if (!assetsBody) return

                const audioItem = document.getElementById(extra.hotspotName) as HTMLAudioElement;

                if (audioItem?.src) {
                    const soundsAudioData = pauseSceneSound(sceneStore.currentAudioElement, sceneStore.currentVideoElement)

                    audioItem.currentTime = 0
                    playMediaSafely(audioItem)

                    audioItem.onended = function (evt) {
                        console.log('Hotspot audio is ended');
                        playSceneSound(soundsAudioData, sceneStore.currentAudioElement, sceneStore.currentVideoElement)
                    }
                }

                break;
            case HotspotBehaviourType.picture:
                modals.open({
                    ...hotspotModalOptions,
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data} extra={extra}/>
                    ),
                });
                break;
            case HotspotBehaviourType.video:
                const soundsVideoData = pauseSceneSound(sceneStore.currentAudioElement, sceneStore.currentVideoElement)

                modals.open({
                    ...hotspotModalOptions,
                    onClose: () => {
                        playSceneSound(soundsVideoData, sceneStore.currentAudioElement, sceneStore.currentVideoElement)
                    },
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    ),
                });
                break;
            case HotspotBehaviourType.video360:
                const soundsVideo360Data = pauseSceneSound(sceneStore.currentAudioElement, sceneStore.currentVideoElement)

                modals.open({
                    ...hotspotModalOptions,
                    onClose: () => {
                        playSceneSound(soundsVideo360Data, sceneStore.currentAudioElement, sceneStore.currentVideoElement)
                    },
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    ),
                });
                break;
            case HotspotBehaviourType.album:
                modals.open({
                    ...hotspotModalOptions,
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    ),
                });
                break;
            case HotspotBehaviourType.picture360:
                modals.open({
                    ...hotspotModalOptions,
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    )
                });
                break;
            case HotspotBehaviourType.pdf:
            case HotspotBehaviourType.text:
                modals.open({
                    ...hotspotModalOptions,
                    withCloseButton: !isMobile(),
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data} />
                    ),
                });
                break;
            case HotspotBehaviourType.link:
                const linkData = data as HotspotBehaviourLink;

                if (linkData.target === '_blank' || linkData.target === '_self') {
                    window.open(linkData.uri, linkData.target);
                } else {
                    console.error('Unknown target link type!')
                }
                break;
            default:
                console.error('Unknown scene type!')
        }
    }

    function showMinimap() {
        const selectedMinimapIndex = getSelectedPlanIndex(plan2d, sceneStore);

        plan2DStore.setCurrent2DPlanElement(null);
        sceneStore.setLoadedScene(false);

        if (selectedMinimapIndex !== -1) {
            if (tourStore.mode360TourSettings.show2dPlanMinimap) {
                setMinimap(
                    <Minimap2dPlan plan2DData={plan2d}
                                   loadContent={loadViewerContent}/>
                );
            }
        }
    }

    useDidUpdate(() => {
        if (tourStore.switcherMode === ViewMode.map) {
            loadViewerContent(ViewerContentType.map)
        } else if (tourStore.switcherMode === ViewMode.measurement) {
            loadViewerContent(ViewerContentType.measurement)
        } else if (tourStore.switcherMode === ViewMode.scene360) {
            loadViewerContent(ViewerContentType.scene360)
        } else if (tourStore.switcherMode === ViewMode.plan) {
            loadViewerContent(ViewerContentType.scene360, '', false, true)
        }
    },[tourStore.switcherMode])

    const loadViewerContent = (mode: ViewerContentType, nodeName: string = '', init: boolean = false, showPlan: boolean = false) => {
        if (ViewerContentType[mode] === undefined) return;

        const key = Math.floor(Date.now() / 1000)

        show2dPlan && setShow2dPlan(false)

        switch (mode) {
            case ViewerContentType.map:
                setScene(
                    <MapScene toggle={toggle}
                              key={key}/>
                )
                minimap && setMinimap(null)
                tourStore.setSwitcherMode(ViewMode.map);
                tourStore.ifHideUiForPopup && tourStore.setIfHideUiForPopup(false)
                sceneStore.setCurrentScene(null);
                stateStore.updateAndSave({mode: ViewMode.map}, 'viewer set map mode')
                break;
            case ViewerContentType.measurement:
                setScene(
                    <MeasurementScene toggle={toggle}
                                      key={key}/>
                )
                minimap && setMinimap(null)
                tourStore.setSwitcherMode(ViewMode.measurement);
                tourStore.ifHideUiForPopup && tourStore.setIfHideUiForPopup(false)
                sceneStore.setCurrentScene(null);
                stateStore.updateAndSave({mode: ViewMode.measurement}, 'viewer set measurement mode')
                break;
            case ViewerContentType.scene360:
                if (!showPlan || (showPlan && !sceneStore.currentScene)) {
                    const nodeData = getNodeData(nodeName);

                    if (!nodeData || Object.keys(nodeData).length < 1) return;

                    const viewMode = !showPlan ? ViewMode.scene360 : ViewMode.plan;
                    tourStore.setSwitcherMode(viewMode);

                    if (!showPlan) {
                        tourStore.ifHideUiForPopup && tourStore.setIfHideUiForPopup(false)
                        if (sceneStore.currentScene?.nodeName === nodeData.nodeName) {
                            stateStore.updateAndSave({mode: viewMode}, `viewer set ${viewMode} mode`)
                            return;
                        }
                    }

                    if (showPlan && !sceneStore.currentScene) {
                        tourStore.setIfHideUiForPopup(true)
                        setShow2dPlan(true)
                    }

                    const type = nodeData.type === 1 ? Scene360Type.image : Scene360Type.video;

                    sceneStore.setCurrentSceneType(type);
                    sceneStore.setCurrentSceneZoomData(nodeData.cameraData, init)
                    sceneStore.setCurrentScene(nodeData);

                    let showMinimapValue: boolean = false;
                    const zoomStoreData = stateStore.checkByKey('zoom')
                    const rotationStoreData = stateStore.checkByKey('rotation')

                    const updated = {
                        sceneNodeName: nodeData.nodeName,
                        zoom: zoomStoreData.exist ? zoomStoreData.val : nodeData.cameraData?.initialZoom,
                        rotation: rotationStoreData.exist ? rotationStoreData.val : nodeData.cameraData?.initialRotation,
                        mode: viewMode
                    };

                    if (!init && nodeData.nodeName !== stateStore.state.sceneNodeName) {
                        const selectedPlanIndex = getSelectedPlanIndex(plan2d, sceneStore);

                        if (selectedPlanIndex !== -1) {
                            showMinimapValue = tourStore.mode360TourSettings.show2dPlanMinimap;
                        }
                    }

                    showMinimapValue && (updated['showMinimap'] = showMinimapValue)

                    if (!init) {
                        if (nodeData.nodeName !== stateStore.state.sceneNodeName) {
                            updated['rotation'] = nodeData.cameraData?.initialRotation || defaultCameraRotation
                        }
                        // TODO -> feature -> reset scene store
                    }

                    stateStore.updateAndSave(updated, 'viewer loadViewerContent update store 111111111111')

                    if (type === Scene360Type.image) {
                            setScene(<ImageScene sceneData={nodeData}
                                                 clickHandler={hotspotBehaviour}
                                                 toggle={toggle}
                                                 key={key}/>)
                        } else {
                            setScene(<VideoScene sceneData={nodeData}
                                                 clickHandler={hotspotBehaviour}
                                                 toggle={toggle}
                                                 key={key}/>)
                        }

                    showMinimap();
                } else {
                    tourStore.setSwitcherMode(ViewMode.plan);
                    tourStore.setIfHideUiForPopup(true)
                    stateStore.updateAndSave({mode: ViewMode.plan}, 'viewer loadViewerContent update store')
                    setShow2dPlan(true)
                }
                break;
        }
    }

    function twoDPlan() {
        if (tourStore.switcherMode === 'plan') {
            return true;
        }

        return !tourStore.ifHideUiForPopup;
    }

    return (
        <Box className={classes.viewerContainer}>
            <Box id="virtikViewerContent" className={classes.contentArea}>
                <Box id="virtikContentWrapper" className={classes.sceneArea} ref={sceneWrapperRef}>
                    {!tourStore.ifHideUiForPopup && sceneStore.loadedScene &&
                        <>
                            {minimap}
                        </>
                    }

                    {
                        (
                            !stateStore.state.fullscreen &&
                            (
                                (orientation.includes('portrait') && width < mobileDeviceMaxWidth)
                                ||
                                (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth)
                            )
                        ) &&
                        <Box className={classes.modalWindowModeNotifyBlock}>
                            <Box p={4} className={classes.modalWindowModeNotifyImage}>
                                <Image w={24} h={24} src={maximize}></Image>
                            </Box>

                            <Text className={classes.modalWindowModeNotifyText}>
                                {t('Sorry, software does not support window mode on mobile devices. ' +
                                    'Please, go to the full screen for better experience')}
                            </Text>

                            <Button onClick={() => {
                                toggle()
                            }}
                                    className={classes.modalWindowModeNotifyButton}>
                                {t('Full screen mode')}
                            </Button>
                        </Box>
                    }

                    {sidebarData.current &&
                        <SidebarMenu sidebarData={sidebarData.current}
                                     loadViewerContent={loadViewerContent}
                                     clickHandler={hotspotBehaviour}
                                     isVisibleUi={stateStore.state.visibleUi ?? false}
                                     classes={classes}/>
                    }

                    {scene}

                    {show2dPlan && plan2d && <PlanMode plan2DData={plan2d}
                                                       loadContent={loadViewerContent}/>}

                    {tourStore.generalSettings.showModeSwitcher && stateStore.state.visibleUi && twoDPlan() &&
                        <Box className={classes.bottomSceneSwitcher}>
                            <BottomSceneSwitcher/>
                        </Box>
                    }

                    <CustomOverlay visible={showLoadingFor360Scene}></CustomOverlay>
                </Box>
            </Box>
        </Box>
    )
});

export default withTranslation()(Viewer360);
