import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {I2DPlanScene, MiniMapState} from "../../../models/shared/Plan2D";
import {globalContext} from "../../../App";
import {actionMoveToCurrentHotspot, getRenderedHotspots, getSelectedPlanIndex} from "../utils/Plan2D";
import {Box} from "@mantine/core";
import cx from "clsx";
import c from "./Minimap2dPlan.module.css";
import {useDidUpdate} from "@mantine/hooks";

const Minimap2dPlan = observer(({plan2DData, loadContent}: IProps) => {
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const planImages: string[] = [];
    const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(0);
    const [renderHotspots, setRenderHotspots] = useState<any>();
    const [notRender, setNotRender] = useState<boolean>(false);
    const [showMinimap, setShowMinimap] = useState<"block" | "none">('none');
    const [sizeMinimap, setSizeMinimap] = useState<MiniMapState>(MiniMapState.small);
    const [scaleMinimap, setScaleMinimap] = useState<number>(1);
    const blockId: string = 'minimap-block';
    const contentId: string = 'minimap-content';
    const childId: string = 'minimap-child';
    const imageId: string = 'minimap-image';

    useEffect(() => {
        if (notRender) {
            return;
        }

        //Set array with source image link
        if (Object.keys(plan2DData).length !== 0) {
            const result: I2DPlanScene[] = Object.values(plan2DData);

            for (const plan of result) {
                if (plan.nodeDataPath != null && plan.nodeDataLink != null) {
                    planImages.push(plan.nodeDataLink);
                }
            }
        }

        //Set current 2D plan element
        let currentPlanImageSrc = "";
        const selectedPlanIndex = getSelectedPlanIndex(plan2DData, sceneStore);

        if (selectedPlanIndex === -1) {
            plan2DStore.setIssetMinimap(false);
            return;
        } else {
            currentPlanImageSrc = planImages[selectedPlanIndex];
            setSizeMinimap(tourStore.mode360TourSettings.minimapState || MiniMapState.small);

            if (stateStore.state.showMinimap === null) {
                stateStore.updateAndSave(
                    {showMinimap: tourStore.mode360TourSettings.show2dPlanMinimap ?? true},
                    'content minimap update showMinimap')
            }

            plan2DStore.setCurrent2DPlanElement(selectedPlanIndex);
            setCurrentPlanIndex(selectedPlanIndex);
            plan2DStore.setIssetMinimap(true);
        }

        let i = setInterval(function () {
            const content: HTMLElement = document.getElementById(blockId) as HTMLDivElement;
            const planChild = document.getElementById(childId) as HTMLDivElement;
            const planImage = document.getElementById(imageId) as HTMLImageElement;

            if (planImage) {
                if (stateStore.state.showMinimap) {
                    setShowMinimap('block');
                } else {
                    setShowMinimap('none');
                }

                clearInterval(i);
                setPlanImage(planImage, currentPlanImageSrc, content, planChild);
            }
        }, 200);
    }, [sizeMinimap, currentPlanIndex, stateStore.state.showMinimap]);

    useDidUpdate(() => {
        setRenderHotspots(getRenderedHotspots(imageId, loadContent, true, sizeMinimap))
    }, [sizeMinimap]);

    function setPlanImage(planImage: HTMLImageElement, currentPlanImageSrc: string, content: HTMLElement, planChild: HTMLElement) {
        planImage.setAttribute("src", currentPlanImageSrc);

        const scaleToSet = /*tourStore.plan[currentPlanIndex ?? 0].miniMap?.scale ||*/ 1; // not available for skin version but can be easily added here
        setScaleMinimap(scaleToSet);
        // TODO is it necessary yet? What's the difference of scaleToValue with scaleToSet?
        if (/*tourStore.plan[currentPlanIndex ?? 0]?.miniMap?.scale && */sizeMinimap === MiniMapState.small) {
            const scaleToValue = /*tourStore.plan[currentPlanIndex ?? 0].miniMap?.scale || */1;
            planChild.style.cssText = 'width: ' + (100 * scaleToValue) + '%';
        } else {
            planChild.style.cssText = 'width: 100%; height: 100%';
        }

        planImage.onload = function (e) {
            setRenderHotspots(getRenderedHotspots(imageId, loadContent, true))
            actionMoveToCurrentHotspot(sceneStore.currentScene!.nodeName, contentId, childId, imageId);
            tourStore.setShowLoadingFor360Scene(false);
        };

        //Need if image load from cache
        actionMoveToCurrentHotspot(sceneStore.currentScene!.nodeName, contentId, childId, imageId);
    }

    const changeSizeMinimap = (event: any) => {
        const child = document.getElementById(childId) as HTMLDivElement;

        if (sizeMinimap === MiniMapState.small) {
            setSizeMinimap(MiniMapState.big);
            child.style.cssText = 'width: 100%; height: 100%';
        } else {
            setSizeMinimap(MiniMapState.small);
            child.style.cssText = 'width: ' + (100 * scaleMinimap) + '%';
            actionMoveToCurrentHotspot(sceneStore.currentScene!.nodeName, contentId, childId, imageId);
        }

        setNotRender(true);
    }

    return (
        <>
            {tourStore.mode360TourSettings.show2dPlanMinimap && plan2DStore.issetMinimap &&
                <Box style={{display: showMinimap}}
                     onClick={changeSizeMinimap}
                     id={blockId}
                     className={cx(c.minimap, sizeMinimap === MiniMapState.small ? c.minimapSmallSize : c.minimapBigSize)}>
                    <Box id={contentId} className={c.currentPlanContent}>
                        <Box id={childId} className={c.childMinimap}>
                            <img id={imageId} className={c.minimapImage} src="" alt=""/>
                            {renderHotspots}
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
})

export default Minimap2dPlan

interface IProps {
    plan2DData: I2DPlanScene[];
    loadContent: CallableFunction;
}
