import {useContext, useEffect, useState} from "react";
import {Button, Image} from "@mantine/core";
import cx from 'clsx';
import {observer} from "mobx-react-lite";
import {globalContext} from "../../../App";
import close from "../../../assets/images/close.png";
import mute from "../../../assets/images/mute.png";
import unmute from "../../../assets/images/unmute.png";
import burger from "../../../assets/images/burger.png";
import currentLocation from "../../../assets/images/current-location.png";
import {HotspotBehaviourLocation} from "../../../models/shared/HotspotBehaviour";
import {debounce} from "../utils/Common";
import classes from "../view/Scenes.module.css";
import ZoomControls from "../view/ZoomControls";

const RightControls2DPlan = observer(() => {
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const [disabledCurrentPositionButton, setDisabledCurrentPositionButton] = useState<boolean>(false);
    const maxScale = 3;
    const stepScale = 0.3;
    const processZoomIn = debounce(() => toggleZoomIn());
    const processZoomOut = debounce(() => toggleZoomOut());
    const contentId: string = 'plan-content';
    const childId: string = 'plan-child';
    const imageId: string = 'plan-image';

    useEffect(() => {
        if (plan2DStore.resetZoomRightControls) {
            plan2DStore.setScale(1);
            plan2DStore.doResetZoomRightControls(false);

            let i = setInterval(function () {
                const planChild = document.getElementById(childId) as HTMLDivElement;

                if (planChild) {
                    clearInterval(i);
                    planChild.addEventListener("wheel", zoomHandler);
                }
            }, 500);
        }
        const index = plan2DStore.current2DPlanElement ?? 0;
        const targetHotspotPos = (tourStore.plan[index]?.hotspots ?? [])
            .filter(h => sceneStore.currentScene!?.nodeName === (h.hotspotData.behaviourData as HotspotBehaviourLocation).transitionToScene)
            .map(h => h.hotspotData.position)
            .slice(0, 1).shift();

        setDisabledCurrentPositionButton(!targetHotspotPos);
    }, [plan2DStore.resetZoomRightControls]);

    function toggleVolume() {
        stateStore.updateAndSave({mute: !stateStore.state.mute}, 'right control 2d update volume')
    }

    function zoomHandler(e) {
        const zoomIn = document.getElementById('zoomIn') as HTMLDivElement;
        const zoomOut = document.getElementById('zoomOut') as HTMLDivElement;

        if (e.wheelDelta < 0) {
            zoomOut && zoomOut.click();
        } else {
            zoomIn && zoomIn.click();
        }

        return false;
    }

    function toggleZoomIn() {
        const content = document.getElementById(contentId) as HTMLDivElement;
        const planImage = document.getElementById(imageId) as HTMLImageElement;
        const planChild = document.getElementById(childId) as HTMLDivElement;
        const currentScale = plan2DStore.scale + stepScale;
        const zoom = currentScale * 100;
        content.style.cssText = 'height: 100%';
        planImage.style.cssText = 'height: 100%';
        planChild.style.cssText = 'height: ' + zoom + '%; width: ' + planImage.width + 'px';
        // recalculate after repaint
        setTimeout(() => planChild.style.width = planImage.width + 'px')

        plan2DStore.setScale(currentScale);
        plan2DStore.setCurrent2DPlanScale(currentScale);
    }

    function toggleZoomOut() {
        const content = document.getElementById(contentId) as HTMLDivElement;
        const planImage = document.getElementById(imageId) as HTMLImageElement;
        const planChild = document.getElementById(childId) as HTMLDivElement;
        const currentScale = plan2DStore.scale - stepScale;
        const zoom = currentScale * 100;

        if (currentScale === 1) {
            const mainContent = document.getElementById('virtikContentWrapper') as HTMLDivElement

            if (planImage.naturalHeight < mainContent.offsetHeight) {
                content.style.cssText = 'height: auto';
                planImage.style.cssText = 'height: none';
            } else {
                content.style.cssText = 'max-height: 100%';
                planImage.style.cssText = 'max-height: 100%';
            }
            planChild.style.cssText = 'height: 100%';
        } else {
            content.style.cssText = 'max-height: 100%';
            planChild.style.cssText = 'height: ' + zoom + '%';
        }

        planChild.style.cssText = 'height: ' + zoom + '%; width: ' + planImage.width + 'px';

        plan2DStore.setScale(currentScale);
        plan2DStore.setCurrent2DPlanScale(currentScale);
    }

    function toggleVisible() {
        stateStore.updateAndSave({isRightControlPanelExpanded: !stateStore.state.isRightControlPanelExpanded}, 'right control update RightControlPanelExpanded')
    }

    function centerPlanViewport() {
        plan2DStore.setIfCenterPlanViewport(true);
    }

    return (
        <>
            {stateStore.state.isRightControlPanelExpanded &&
                <>
                    <Button className={cx(classes.roundButton, classes.zIndexForButtons)}
                            onClick={toggleVisible}>
                        <Image src={close}/>
                    </Button>

                    <Button className={cx(classes.roundButton, classes.zIndexForButtons)}
                            onClick={toggleVolume}>
                        {stateStore.state.mute ? <Image src={mute}/> : <Image src={unmute}/>}
                    </Button>

                    <ZoomControls toggleZoomIn={processZoomIn}
                                  disabledZoomIn={plan2DStore.scale >= maxScale}
                                  toggleZoomOut={processZoomOut}
                                  disabledZoomOut={plan2DStore.scale === 1}/>

                    <Button className={classes.roundButton}
                            disabled={disabledCurrentPositionButton}
                            onClick={centerPlanViewport}>
                        <Image src={currentLocation}/>
                    </Button>
                </>
            }

            {!stateStore.state.isRightControlPanelExpanded &&
                <Button className={cx(classes.roundButton, classes.zIndexForButtons)}
                        onClick={toggleVisible}>
                    <Image src={burger}/>
                </Button>
            }
        </>
    );
})
export default RightControls2DPlan;
