import {makeAutoObservable} from "mobx";
import {GlobalStore} from "./GlobalStore";
import {defaultCameraRotation} from "../vars";
import {IStateControls} from "../models/IStateControls";
import {ViewMode} from "../models/shared/SceneType";
import {ValueOf} from "../components/tour/utils/Types";
import {debounce} from "../components/tour/utils/Common";

export class StateStore {
    global: GlobalStore;
    localStateName: string = 'virtikTourState';
    //TODO Daniil self note: can we get rid of it?
    copyState = {};
    state: IStateControls = {
        mute: false,
        zoom: 1,
        visibleUi: true,
        fullscreen: false,
        sceneNodeName: '',
        mode: ViewMode.scene360,
        referenceCodeTour: '',
        videoLastTime: 0,
        rotation: defaultCameraRotation,
        showSidebar: true,
        isRightControlPanelExpanded: true,
        showMinimap: false,
    }
    processSaveStore = debounce((c) => this.save(c), 500);

    constructor(globalStore: GlobalStore) {
        this.global = globalStore;
        makeAutoObservable(this);
    }

    updateOne(key: keyof IStateControls, value: ValueOf<IStateControls>, force = false) {
        (this.state[key] as ValueOf<IStateControls>) = value;

        if (force) {
            this.copyState[key] = value
        }
    }

    updateAndSave(updatedValues: Partial<IStateControls>, caller: string = '') {
        const localState = this.get();

        (Object.keys(updatedValues) as [keyof IStateControls]).forEach((key) => {
            if (this.state[key] === undefined) return;

            if (localState[key] !== undefined) {
                const val = updatedValues[key] as Exclude<ValueOf<IStateControls>, undefined>; // TS has added undefined type due to Partial
                this.updateOne(key, val)
            }
        })

        this.processSaveStore(caller)
    }

    save(caller: string = '') {
        console.warn('SAVED by', caller);
        localStorage.setItem(this.localStateName, JSON.stringify(this.state));
    }

    init(initialValue: Partial<IStateControls>, resetState: boolean = false) {
        let localState;

        if (resetState) {
            this.remove()
            localState = {};
            this.copyState = {}
        } else {
            localState = this.get()
        }

        const stateKeys = Object.keys(this.state);

        (Object.keys(initialValue) as [keyof Partial<IStateControls>]).forEach((key) => {
            if (this.state[key] === undefined) return;
            const val = initialValue[key] as Exclude<ValueOf<IStateControls>, undefined>; // TS has added undefined type due to Partial

            if (localState[key] === undefined && this.copyState[key] === undefined) {
                this.updateOne(key, val)
            } else if (resetState && localState[key] === undefined && this.copyState[key] !== undefined) {
                this.updateOne(key, this.copyState[key])
                delete this.copyState[key]
            } else if (!resetState && localState[key] !== undefined && this.copyState[key] === undefined) {
                this.updateOne(key, localState[key])
            }

            stateKeys.splice(stateKeys.indexOf(key), 1);
        })

        if (Object.keys(stateKeys).length > 0) {
            (stateKeys as [keyof Partial<IStateControls>]).forEach((key) => {
                if (localState[key] !== undefined) {
                    this.updateOne(key, localState[key])
                }
            })
        }

        this.save('init')
    }

    remove() {
        localStorage.removeItem(this.localStateName);
    }

    checkByKey(key: keyof IStateControls) {
        const localState = this.get()
        const exist = localState[key] !== undefined

        return {exist, val: localState[key]}
    }

    private get() {
        return JSON.parse(localStorage.getItem(this.localStateName) ?? '{}');
    }
}
