import {makeAutoObservable, toJS} from "mobx";
import {GlobalStore} from "./GlobalStore";
import {ViewMode} from "../models/shared/SceneType";
import {INodeData, ITour} from "../models/shared/IProjectBase";
import {IProject} from "../models/shared/IProject";
import {I2DPlanScene, MiniMapState} from "../models/shared/Plan2D";
import Moment from 'moment';
import {
    IGeneralSettings,
    IMode2dPlanSettings,
    IMode360Settings,
    ITheme,
    ScreenModeType,
    UiColorScheme
} from "../models/shared/ITheme";
import {mainBlackBlueWhiteColor, mainBlackRedWhiteColor} from "../vars";
import {MenuType} from "../models/shared/IMenuSettings";

export class TourStore {
    global: GlobalStore;

    projectName: string = "";
    switcherMode: ViewMode;
    projectData?: IProject;
    tourData?: ITour;
    isOpenedMenu: boolean = false;
    ifHideUiForPopup: boolean = false;
    tourNodes: INodeData[] = [];
    _plan: I2DPlanScene[] = [];
    showLoadingFor360Scene: boolean = false;
    lastVisitedTours = this.getLastVisitedTours();
    cameraComponents: any = null;
    generalSettings: IGeneralSettings = defaultGeneral;
    mode360TourSettings: IMode360Settings = default360Mode;
    mode2dPlanSettings: IMode2dPlanSettings = default2dPlanMode;
    uiColorScheme: UiColorScheme
    menuType: MenuType = MenuType.left

    constructor(globalStore: GlobalStore) {
        this.global = globalStore;
        makeAutoObservable(this);
    }

    get isLoaded() {
        return !!this.projectData
    }

    setProjectName(name) {
        this.projectName = name;
    }

    setSwitcherMode(type) {
        this.switcherMode = type;
    }

    setProjectData(data) {
        this.projectData = data;
    }

    setTourData(data) {
        this.tourData = data;
    }

    setToursNodes(data) {
        this.tourNodes = data;
    }

    setPlan(data: I2DPlanScene[]) {
        this._plan = data;
    }

    get plan(): I2DPlanScene[] {
        let planScenes = toJS(this._plan);

        if (!planScenes) {
            return [];
        }

        return planScenes.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
    }

    setIsOpenedMenu(val: boolean) {
        this.isOpenedMenu = val;
    }

    setIfHideUiForPopup(val: boolean) {
        this.ifHideUiForPopup = val;
    }

    setShowLoadingFor360Scene(val: boolean) {
        this.showLoadingFor360Scene = val;
    }

    getLastVisitedTours() {
        let lastTours = JSON.parse(localStorage.getItem('lastVisitedTours') ?? '[]');

        lastTours.sort().reverse();
        return lastTours;
    }
    setCameraComponents(components: any) {
        this.cameraComponents = components;
    }
    setLastVisitedTours(tour) {
        const lastTour = {
            logo: tour.logoLink,
            name: tour.displayName,
            referenceCode: tour.referenceCode,
            date: Moment()
        };

        const lastVisitedToursFromLocalStorage = JSON.parse(localStorage.getItem('lastVisitedTours') ?? '[]');
        const findIndex = (element) => element.referenceCode === tour.referenceCode;
        const index = lastVisitedToursFromLocalStorage.findIndex(findIndex);

        if (index !== -1) {
            lastVisitedToursFromLocalStorage[index] = lastTour;
        } else {
            lastVisitedToursFromLocalStorage.push(lastTour);
        }


        if (lastVisitedToursFromLocalStorage.length > 10) {
            lastVisitedToursFromLocalStorage.shift();
        }

        localStorage.setItem('lastVisitedTours', JSON.stringify(lastVisitedToursFromLocalStorage));
        this.lastVisitedTours = JSON.parse(localStorage.getItem('lastVisitedTours') ?? '[]');
    }

    setSettings(data: ITheme) {
        this.generalSettings = data?.tourDefaultSettings.general
        this.mode360TourSettings = data?.tourDefaultSettings.mode360tour
        this.mode2dPlanSettings = data?.tourDefaultSettings.mode2dPlan
    }

    setUiColorScheme(val: UiColorScheme = UiColorScheme['Black&Blue&White']) {
        this.uiColorScheme = val
    }

    getColorUiScheme(): string {
        if (this.uiColorScheme === UiColorScheme['Black&Red&White']) {
            return mainBlackRedWhiteColor;
        }

        return mainBlackBlueWhiteColor;
    }

    setMenuType(val: MenuType = MenuType.left) {
        this.menuType = val
    }
}


const defaultGeneral = {
    initialMode: ViewMode.scene360,
    isControlsCollapsed: false,
    isMute: false,
    modeSwitcherShowButtons: {
        scene360: true,
        map: true,
        measurement: true,
        plan: true,
    },
    screenMode: ScreenModeType.windowed,
    showInfoMenuForModes: {
        scene360: true,
        map: true,
        measurement: true,
        plan: true,
    },
    showControlsMenuButton: true,
    showModeSwitcher: true,
    showInfoMenuButton: true,
    showInfoMenu: true,
};
const default360Mode = {
    hideIdleVideoControls: false,
    minimapBackground: false,
    minimapBorder: false,
    minimapState: MiniMapState.small,
    show2dPlanMinimap: true,
    showVideoControls: true,
};
const default2dPlanMode = {
    showPlanSwitcherPanel: true,
};
