import React, {useContext} from "react";
import {Box} from "@mantine/core";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";

import {globalContext} from "../../../App";
import tour from "../../../assets/images/mode-switcher/360-tour-v4.svg";
import roadMap from "../../../assets/images/mode-switcher/road-map-fill.svg";
import ruler from "../../../assets/images/mode-switcher/ruler-fill.svg";
import plan from "../../../assets/images/mode-switcher/plan.svg";
import classes from "./BottomSceneSwitcher.module.css";
import {ViewMode} from "../../../models/shared/SceneType";
import {I2DPlanScene} from "../../../models/shared/Plan2D";
import CustomTooltip from "./CustomTooltip";

const BottomSceneSwitcher = observer(({t}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const plan2d: I2DPlanScene[] = tourStore.plan;

    function switchMode(mode?: ViewMode) {
        if (tourStore.switcherMode === mode) {
            return;
        }

        tourStore.setSwitcherMode(mode)
    }

    return (
        <Box className={classes.block}>
            {tourStore.generalSettings.modeSwitcherShowButtons.scene360 &&
                <CustomTooltip label={t("360 Tour")}
                               mode={ViewMode.scene360}
                               src={tour}
                               id={"set360Scene"}
                               isActive={tourStore.switcherMode === ViewMode.scene360}
                               callbackFunc={switchMode}/>
            }

            {tourStore.generalSettings.modeSwitcherShowButtons.map &&
                <CustomTooltip label={t("Map")}
                               mode={ViewMode.map}
                               src={roadMap}
                               isActive={tourStore.switcherMode === ViewMode.map}
                               callbackFunc={switchMode}/>
            }

            {tourStore.generalSettings.modeSwitcherShowButtons.measurement &&
                <CustomTooltip label={t("Measurement")}
                               mode={ViewMode.measurement}
                               src={ruler}
                               isActive={tourStore.switcherMode === ViewMode.measurement}
                               callbackFunc={switchMode}/>
            }

            {tourStore.generalSettings.modeSwitcherShowButtons.plan &&
                <CustomTooltip label={t("2D Plan")}
                               mode={ViewMode.plan}
                               src={plan}
                               disabled={Object.keys(plan2d).length === 0}
                               isActive={tourStore.switcherMode === ViewMode.plan}
                               callbackFunc={switchMode}/>
            }
        </Box>
    );
})

export default withTranslation()(BottomSceneSwitcher)

interface IProps {
    t: any;
}
