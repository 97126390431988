export function draggableContent(containerId: string) {
    const container = document.getElementById(containerId) as HTMLDivElement;
    let startY: number;
    let startX: number;
    let scrollLeft: number;
    let scrollTop: number;
    let isDown: boolean;

    container.addEventListener('mousedown', e => mouseIsDown(e));
    container.addEventListener('mouseup', e => mouseUp(e))
    container.addEventListener('mouseleave', e => mouseLeave(e));
    container.addEventListener('mousemove', e => mouseMove(e));
    container.addEventListener("touchstart", e => mouseIsDownForTouch(e));
    container.addEventListener("touchend", e => mouseLeave(e));
    container.addEventListener("touchcancel", e => mouseLeave(e));
    container.addEventListener('touchmove', e => mouseMoveForTouch(e));
    // prevent dragging image to new tab
    container.addEventListener("dragover",(e) => e.preventDefault(),false);
    container.addEventListener("drop",(e) => e.preventDefault(),false);

    function mouseIsDown(e) {
        isDown = true;
        startY = e.pageY - container.offsetTop;
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
        scrollTop = container.scrollTop;
    }

    function mouseIsDownForTouch(e) {
        isDown = true;
        startY = e.changedTouches[0].pageY - container.offsetTop;
        startX = e.changedTouches[0].pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
        scrollTop = container.scrollTop;
    }

    function mouseUp(e) {
        isDown = false;
    }

    function mouseLeave(e) {
        isDown = false;
    }

    function mouseMove(e) {
        if (isDown) {
            e.preventDefault();
            //Move vertcally
            const y = e.pageY - container.offsetTop;
            const walkY = y - startY;
            container.scrollTop = scrollTop - walkY;

            //Move Horizontally
            const x = e.pageX - container.offsetLeft;
            const walkX = x - startX;
            container.scrollLeft = scrollLeft - walkX;
        }
    }

    function mouseMoveForTouch(e) {
        if (isDown) {
            e.preventDefault();

            for (let i = 0; i < e.changedTouches.length; i++) {
                //Move vertcally
                const y = e.changedTouches[i].pageY - container.offsetTop;
                const walkY = y - startY;
                container.scrollTop = scrollTop - walkY;

                //Move Horizontally
                const x = e.changedTouches[i].pageX - container.offsetLeft;
                const walkX = x - startX;
                container.scrollLeft = scrollLeft - walkX;
            }
        }
    }
}
