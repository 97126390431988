export enum Scene360Type {
    "image" = "image",
    "video" = "video",
}

export enum ViewerContentType {
    "scene360" = "scene360",
    "map" = "map",
    "measurement" = "measurement",
}

export enum ViewMode {
    "scene360" = "scene360",
    "map" = "map",
    "measurement" = "measurement",
    "plan" = "plan",
}
