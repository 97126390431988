import React, {useContext, useState} from "react";
import {AppShell, Box, Button, Image, ScrollArea, Text} from "@mantine/core";
import cx from 'clsx';
import Moment from 'moment';

import close from '../../assets/images/close.png'
import info from '../../assets/images/info.png'
import {globalContext} from "../../App";
import classes from "./SidebarMenu.module.css";
import {
    IMenuBehaviourDataHsActivate,
    IMenuBehaviourDataHsFocus,
    IMenuBehaviourDataLink,
    IMenuBehaviourDataLocation,
    IMenuBehaviourDataMode,
    IMenuBehaviourDataText,
    IMenuSettings,
    MenuBehaviourType,
} from "../../models/shared/IMenuSettings";
import {ViewerContentType, ViewMode} from "../../models/shared/SceneType";
import SidebarMenuLevel from "./SidebarMenuLevel";
import SidebarMenuDevelopMode from "./SidebarMenuDevelopMode";
import {HotspotBehaviourType} from "../../models/shared/HotspotBehaviour";

export default function SidebarMenu(props: IProps) {
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const tourStore = global.tourStore;
    const [isVisible, setIsVisible] = useState<boolean>(stateStore.state.showSidebar as boolean);
    const menuItems = props.sidebarData
    const menuType = tourStore.menuType
    const developMode = true

    function toggleMenu() {
        setIsVisible(!isVisible)
        stateStore.updateAndSave({'showSidebar': !isVisible}, 'sidebar toggle')
        tourStore.setIsOpenedMenu(!isVisible);
    }

    function showMenuForSwitcherMode() {
        if (!!tourStore.switcherMode) {
            return tourStore.generalSettings.showInfoMenuForModes[tourStore.switcherMode];
        }

        return false
    }

    function switchMode(mode?: ViewMode) {
        if (tourStore.switcherMode === mode) {
            return;
        }

        tourStore.setSwitcherMode(mode)
    }

    function optionsOnClick(data) {
        if (data?.subOptions && Object.keys(data.subOptions).length !== 0) {
            return;
        }

        switch (data.behaviourType) {
            case MenuBehaviourType.location:
                props.loadViewerContent(ViewerContentType.scene360, (data.behaviourData as IMenuBehaviourDataLocation)?.transitionToScene)
                break;
            case MenuBehaviourType.link:
                const dataLink = data.behaviourData as IMenuBehaviourDataLink

                if (dataLink.target === '_blank' || dataLink.target === '_self') {
                    window.open(dataLink.uri, dataLink.target);
                } else {
                    console.error('Unknown target link type!')
                }

                break;
            case MenuBehaviourType.textModal:
                props.clickHandler(HotspotBehaviourType.text, data.behaviourData as IMenuBehaviourDataText)
                break;
            case MenuBehaviourType.switchMode:
                switchMode((data.behaviourData as IMenuBehaviourDataMode)?.targetMode);
                break;
            case MenuBehaviourType.activateHotspot:
                // TODO не закончено поскольку хз как повернуть камеру
                const dataHsActive = data.behaviourData as IMenuBehaviourDataHsActivate
                // props.loadViewerContent(ViewerContentType.scene360, dataHsActive.sceneNode, false, true, dataHsActive)
                props.loadViewerContent(ViewerContentType.scene360, dataHsActive.sceneNode)
                break;
            case MenuBehaviourType.focusHotspot:
                // TODO не закончено поскольку хз как повернуть камеру
                const dataHsFocus = data.behaviourData as IMenuBehaviourDataHsFocus
                // props.loadViewerContent(ViewerContentType.scene360, dataHsFocus.sceneNode, false, true, dataHsFocus)
                props.loadViewerContent(ViewerContentType.scene360, dataHsFocus.sceneNode)
                break;
        }
    }

    return (
        <>
            {props.isVisibleUi &&
                ((tourStore.switcherMode === ViewMode.plan && tourStore.generalSettings.showInfoMenuForModes.plan && showMenuForSwitcherMode()) || (!tourStore.ifHideUiForPopup && showMenuForSwitcherMode())) &&
                <Box className={cx(classes.sidebarMenu, classes[menuType + 'MenuSide'])}>
                    {(isVisible && menuItems) &&
                        <AppShell>
                            <AppShell.Navbar withBorder={false} className={classes.sidebarMenuNavbar}>
                                {/*TODO scroll area exist but not working*/}
                                <AppShell.Section className={classes.listMenuItems} component={ScrollArea}>
                                    {
                                        menuItems?.logoPath &&
                                        <Image h={80}
                                               className={classes.borderItem}
                                               src={menuItems.logoLink}
                                        />
                                    }
                                    {
                                        menuItems?.displayName &&
                                        <Text className={cx(classes.borderItem, classes.nameBody)} lineClamp={3}>
                                            {menuItems?.displayName}
                                        </Text>
                                    }
                                    {
                                        menuItems?.description &&
                                        <Text className={cx(classes.borderItem, classes.descriptionBody)}
                                              lineClamp={10}>
                                            {menuItems?.description}
                                        </Text>
                                    }
                                    {
                                        menuItems?.eventDate &&
                                        <Text className={cx(classes.borderItem, classes.dateBody)} lineClamp={1}>
                                            {Moment.unix(menuItems.eventDate).format('DD.MM.YYYY')}
                                        </Text>
                                    }
                                    {
                                        menuItems?.author &&
                                        <Text className={cx(classes.borderItem, classes.dateBody)} lineClamp={2}>
                                            Author: {menuItems?.author}
                                        </Text>
                                    }
                                    {
                                        (menuItems?.options && menuItems?.options?.length > 0) &&
                                        menuItems.options.map((elem) =>
                                            <Box key={elem.title} className={classes.sidebarOptionsBox}>
                                                <SidebarMenuLevel elem={elem} optionsOnClick={optionsOnClick}
                                                                  nestLvl={0}/>
                                            </Box>
                                        )
                                    }

                                    {developMode &&
                                        <SidebarMenuDevelopMode loadViewerContent={props.loadViewerContent}/>
                                    }
                                    {/*TODO remove after implement ScrollArea*/}
                                    {/*{Array(60)*/}
                                    {/*    .fill(0)*/}
                                    {/*    .map((_, index) => (*/}
                                    {/*        <Skeleton key={index} h={28} mt="sm" animate={false} />*/}
                                    {/*    ))}*/}
                                </AppShell.Section>
                            </AppShell.Navbar>
                        </AppShell>
                    }

                    {tourStore.generalSettings?.showInfoMenuButton &&
                        <Box className={
                            cx(
                                classes.buttonArea,
                                isVisible ? classes.buttonAreaShowPosition : classes.buttonAreaHidePosition,
                                isVisible ? classes[menuType + 'ButtonAreaShowPosition'] : classes[menuType + 'ButtonAreaHidePosition']
                            )}>
                            <Button className={classes.roundButton} onClick={toggleMenu}>
                                <Image src={isVisible ? close : info}/>
                            </Button>
                        </Box>
                    }
                </Box>
            }
        </>
    );
}

interface IProps {
    sidebarData: IMenuSettings | null;
    loadViewerContent: Function;
    clickHandler: Function;
    isVisibleUi: boolean;
    classes: any;
}
