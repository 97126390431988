import {Box} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";

import {globalContext} from "../../App";
import classes from "./plan2d/Plan2D.module.css"
import {draggableContent} from "./utils/Draggable";
import {actionMoveToCurrentHotspot, getRenderedHotspots, getSelectedPlanIndex} from "./utils/Plan2D";
import {I2DPlanScene} from "../../models/shared/Plan2D";
import cx from "clsx";
import {mobileDeviceMaxWidth} from "../../vars";
import RightControls2DPlan from "./plan2d/RightControls2DPlan";
import {useWindowDimensions} from "./hooks/useWindowDimensions";
import sceneClasses from "./view/Scenes.module.css";
import viewerClasses from './Viewer360.module.css'
import Slider from "react-slick";
import {slider2DSettings} from "./plan2d/Slider2DSettings";
import currentLocation from "../../assets/images/current_location.svg";
import {useDidUpdate} from "@mantine/hooks";

const PlanMode = observer(({plan2DData, loadContent}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const stateStore = global.stateStore;
    const plan2DStore = global.plan2DStore;
    const planImages: string[] = [];
    const [renderHotspots, setRenderHotspots] = useState<any>();
    let content: HTMLElement;
    const {orientation, width, height} = useWindowDimensions()
    const menuType = tourStore.menuType
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [attachedPlanIndex, setAttachedPlanIndex] = useState(
        getSelectedPlanIndex(tourStore.plan, sceneStore)
    );
    const contentId: string = 'plan-content';
    const childId: string = 'plan-child';
    const imageId: string = 'plan-image';

    useEffect(() => {
        setClassNames();

        //Set array with source image link
        if (Object.keys(plan2DData).length !== 0) {
            const result: I2DPlanScene[] = Object.values(plan2DData);

            for (const plan of result) {
                if (plan.nodeDataPath != null && plan.nodeDataLink != null) {
                    planImages.push(plan.nodeDataLink);
                }
            }
        }

        //Set current 2D plan element
        let currentPlanImageSrc = "";

        if (plan2DStore.current2DPlanElement !== null) {
            currentPlanImageSrc = planImages[plan2DStore.current2DPlanElement];
        } else {
            const selectedPlanIndex = getSelectedPlanIndex(plan2DData, sceneStore);

            if (selectedPlanIndex === -1) {
                currentPlanImageSrc = planImages[0];
                plan2DStore.setCurrent2DPlanElement(0);
            } else {
                currentPlanImageSrc = planImages[selectedPlanIndex];
                plan2DStore.setCurrent2DPlanElement(selectedPlanIndex);
            }
        }

        const planImage = document.getElementById(imageId) as HTMLImageElement;
        const planChild = document.getElementById(childId) as HTMLDivElement;

        if (planImage) {
            setPlanImage(planImage, currentPlanImageSrc, planChild);
        }

        draggableContent(contentId);
    }, [plan2DStore.current2DPlanElement]);

    useEffect(() => {
        //If current hotspot position button clicked
        if (plan2DStore.ifCenterPlanViewport) {
            if (plan2DStore.getCurrentHotspots()) {
                actionMoveToCurrentHotspot(sceneStore.currentScene!.nodeName, contentId, childId, imageId);
                plan2DStore.setIfCenterPlanViewport(false);
            }

            return;
        }
    }, [plan2DStore.ifCenterPlanViewport]);

    useEffect(() => {
        selectedPlan(plan2DStore.current2DPlanElement);
    }, [plan2DStore.current2DPlanElement]);

    useDidUpdate(() => {
        setRenderHotspots(getRenderedHotspots(imageId, loadContent))
    }, [plan2DStore.scale]);

    function selectedPlan(index) {
        setSelectedIndex(index);
        plan2DStore.setCurrent2DPlanElement(index);
        setAttachedPlanIndex(getSelectedPlanIndex(tourStore.plan, sceneStore))
    }

    function setClassNames() {
        content = document.getElementById(contentId) as HTMLDivElement;
        content && content.classList.add(classes.contentElementOpenModal);
    }

    function setPlanImage(planImage: HTMLImageElement, currentPlanImageSrc: string, planChild: HTMLElement) {
        tourStore.setShowLoadingFor360Scene(true);
        planImage.setAttribute("src", currentPlanImageSrc);

        planImage.onload = function (e) {
            plan2DStore.doResetZoomRightControls(true);
            content.style.cssText = 'max-height: 100%';
            content.style.cssText = 'height: auto';
            planChild.style.cssText = 'height: 100%';

            const mainContent = document.getElementById('virtikContentWrapper') as HTMLDivElement

            if ((e.currentTarget as HTMLImageElement).height < mainContent.offsetHeight) {
                content.style.cssText = 'height: auto';
            } else {
                content.style.cssText = 'height: auto';
                content.style.cssText = 'max-height: 100%';
                planImage.style.cssText = 'max-height: 100%';
            }

            setRenderHotspots(getRenderedHotspots(imageId, loadContent))
            tourStore.setShowLoadingFor360Scene(false);
        };
    }

    return (
        <Box id="current-plan-body" className={classes.currentPlanBody}>
            <Box id={contentId} className={classes.currentPlanContent}>
                <Box id={childId} className={classes.child}>
                    <img id={imageId} src="" alt=""/>
                    {renderHotspots}
                </Box>
            </Box>

            {stateStore.state.visibleUi &&
                <Box className={cx(
                    (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth)
                        ? sceneClasses.sceneControlsTop
                        : sceneClasses.sceneControls,
                    sceneClasses[menuType + 'SceneControls'],
                    sceneClasses.zIndexForButtons)}>
                    <RightControls2DPlan/>
                </Box>
            }

            {stateStore.state.visibleUi && tourStore.isLoaded && tourStore.mode2dPlanSettings.showPlanSwitcherPanel &&
                <Box className={viewerClasses.bottomScene2DPlanSwitcher}>
                    <Box className={classes.navPlanContent}>
                        <Box className={classes.navPlanContentChild}>
                            <Slider {...slider2DSettings}>
                                {
                                    (tourStore.plan && tourStore.plan.length > 0) &&
                                    tourStore.plan.map((elem, index) =>
                                            <div key={index} id={'plan_' + index}
                                                 onClick={() => selectedPlan(index)}
                                                 style={{width: 115}}
                                                 className={cx(classes.navSlide, {[classes.selectedPlan]: index === selectedIndex})}>
                                                <span>
                                                    <img className={cx(classes.currentLocation,
                                                        {[classes.attachedPlanIcon]: index === attachedPlanIndex})}
                                                         src={currentLocation}/>
                                                </span>
                                                <span>{elem.sceneName}</span>
                                            </div>
                                    )
                                }
                            </Slider>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>

    );
})

export default PlanMode

interface IProps {
    plan2DData: I2DPlanScene[];
    loadContent: Function
}
